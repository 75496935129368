import React from "react";
import { Stack, Button, IconButton, Typography, Box } from "@mui/material";
import { getCompanyProfile } from "../redux/actions/jobOpportunitiesAction";
import checkmark from '../assets/images/checkMark.png'
import { GoPrimitiveDot } from 'react-icons/go'
import { useEffect } from "react";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { useSelector, useDispatch } from "react-redux";
import { PageLoading } from "../assets/PageLoading";
import { useParams, useNavigate } from "react-router-dom";

import { Tooltip } from '@mui/material'
const CompanyProfile = () => {
  const accessToken = useSelector(
    (state) => state.userTokensReducer.tokens.accessToken
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const { Get_Attendece, isloading_Attendence } = useSelector(
    (state) => state.jobOpportunitiesReducer
  );
  const { industries } = Get_Attendece;

    console.log(industries)



  useEffect(() => {
    getCompanyProfile(dispatch, accessToken, navigate, id);
  }, []);
  return (
    <>
      {isloading_Attendence ? (
        <PageLoading />
      ) : (
        <div>
          <Stack
            sx={{ px: 3, mb: 2 }}
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <IconButton
              onClick={() => {
                navigate("/company");
              }}
              aria-label="edit"
            >
              <AiOutlineArrowLeft color="#2f6deb" />
              <Typography sx={{ mx: 1 }} variant="h6" color="#2f6deb">
                Back
              </Typography>
            </IconButton>
          </Stack>
          <div className="profile_card">
            <h1 className="text-center  Profile_heading ">
              Company Information
            </h1>
          </div>
          <Box
            className="profile_card"
            mt="10px"
            display="grid"
            gridTemplateColumns="repeat(12, 1fr)"
          >
            <Box
              gridColumn='span 6'
              gridRow='span 1'
            >
              <h3 className="get_heading">
                <span className="text-muted" > Company Name : </span>   {Get_Attendece?.company_name}
              </h3>
            </Box>
            <Box
              gridColumn='span 6'
            >
              <h3 className="get_heading">
                <span className="text-muted" >Email : </span>   {Get_Attendece?.email}
              </h3>
            </Box>
            <Box
              gridColumn='span 12'
            >
              <h3 className="get_heading">
                <span className="text-muted" >Description : </span>   {Get_Attendece?.description}
              </h3>
            </Box>
            <Box
              gridColumn='span 6'
            >
              <h3 className="get_heading">
                <span className="text-muted" >Address : </span>   {Get_Attendece?.address}
              </h3>
            </Box>
            <Box
              gridColumn='span 6'
            >
              <h3 className="get_heading">
                <span className="text-muted" >Industry : </span>   {industries[0]?.title}
              </h3>
            </Box>
            <Box
              gridColumn='span 6'
            >
              <h3 className="get_heading">
                <span className="text-muted" >city : </span>   {Get_Attendece?.city}
              </h3>
            </Box>
            <Box
              gridColumn='span 6'
            >
              <h3 className="get_heading">
                <span className="text-muted" >State : </span>   {Get_Attendece?.state}
              </h3>
            </Box>
            <Box
              gridColumn='span 6'
            >
              <h3 className="get_heading">
                <span className="text-muted" >Zip : </span>   {Get_Attendece?.zip}
              </h3>
            </Box>
            <Box
              gridColumn='span 6'
            >
              <h3 className="get_heading">
                <span className="text-muted" >Country : </span>   {Get_Attendece?.country}
              </h3>
            </Box>
            <Box
              gridColumn='span 6'
            >
              <h3 className="get_heading">
                <span className="text-muted" >Region Of Formation : </span>   {Get_Attendece?.region_of_formation}
              </h3>
            </Box>


          </Box>

          <div className="profile_card card_separater d-flex justify-content-end ">
            <div className="d-flex align-items-center justify-content-between w-75">
              <h1 className="text-center Profile_heading extra_margin">
                Primary Information
              </h1>
              <p className="h4 status_profile">
                <Tooltip title={Get_Attendece.is_level_two_profile ? "Level Two User" : " No Level Two User"}  >

                  <span style={{ width: "25px", height: "25px" }}>
                    {Get_Attendece.is_level_two_profile ? (
                      <img
                        src={checkmark}
                        alt="icon"
                        width={"30px"}
                      />
                    ) : (
                      <GoPrimitiveDot style={{ width: "22px" }} />
                    )}
                  </span>


                </Tooltip>

              </p>

              {/* <p className="h6 status_profile">
                      <span
                        class="badge badge-danger bg-danger mx-3"
                        style={{ boxShadow: "4px 1px 13px -1px #red" }}
                      >
                        In Valid
                      </span>
                    </p> */}

            </div>
          </div>



          {/* <div className="profile_card mt-3">
            <h1 className="text-center  Profile_heading ">
              Primary Contacts
            </h1>
          </div> */}
          <Box
            className="profile_card"
            mt="10px"
            display="grid"
            gridTemplateColumns="repeat(12, 1fr)"
          >
            <Box
              gridColumn='span 6'
              gridRow='span 1'
            >
              <h3 className="get_heading">
                <span className="text-muted" >First Name : </span>   {Get_Attendece?.primary_contact_first_name ? Get_Attendece?.primary_contact_first_name : "---"}
              </h3>
            </Box>
            <Box
              gridColumn='span 6'
            >
              <h3 className="get_heading">
                <span className="text-muted" >Last Name : </span>   {Get_Attendece?.primary_contact_last_name ? Get_Attendece?.primary_contact_last_name : "---"}
              </h3>
            </Box>
            <Box
              gridColumn='span 6'
            >
              <h3 className="get_heading">
                <span className="text-muted" >Email : </span>   {Get_Attendece?.primary_contact_email ? Get_Attendece?.primary_contact_email : "---"}
              </h3>
            </Box>
            <Box
              gridColumn='span 6'
            >
              <h3 className="get_heading">
                <span className="text-muted" >Contact Number : </span>{Get_Attendece?.primary_contact_number ? Get_Attendece?.primary_contact_numbers : "---"}
              </h3>
            </Box>
            <Box
              gridColumn='span 6'
            >
              <h3 className="get_heading">
                <span className="text-muted" >Date Of Birth : </span>   {Get_Attendece?.primary_contact_date_of_birth ? Get_Attendece?.primary_contact_date_of_birth : "---"}
              </h3>
            </Box>
            <Box
              gridColumn='span 6'
            >
              <h3 className="get_heading">
                <span className="text-muted" >Tax Identification Number : </span>   {Get_Attendece?.primary_contact_tax_identification_number ? Get_Attendece?.primary_contact_tax_identification_number : "---"}
              </h3>
            </Box>
            <Box
              gridColumn='span 12'
            >
              <h3 className="get_heading">
                <span className="text-muted" >Address : </span>   {Get_Attendece?.primary_contact_address ? Get_Attendece?.primary_contact_address : "---"}
              </h3>
            </Box>
            <Box
              gridColumn='span 6'
            >
              <h3 className="get_heading">
                <span className="text-muted" >Zip : </span>   {Get_Attendece?.primary_contact_zip ? Get_Attendece?.primary_contact_zip : "---"}
              </h3>
            </Box>
            <Box
              gridColumn='span 6'
            >
              <h3 className="get_heading">
                <span className="text-muted" >City : </span>   {Get_Attendece?.primary_contact_city}
              </h3>
            </Box>
            <Box
              gridColumn='span 6'
            >
              <h3 className="get_heading">
                <span className="text-muted" >State : </span>   {Get_Attendece?.primary_contact_state}
              </h3>
            </Box>
            <Box
              gridColumn='span 6'
            >
              <h3 className="get_heading">
                <span className="text-muted" >Country : </span>   {Get_Attendece?.primary_contact_country}
              </h3>
            </Box>
          </Box>












          {/* <div className="row profile_card">
            <div className="col-lg-6 col-12">
              <h3 className="get_heading">
                Company Name : {Get_Attendece.company_name}
              </h3>
              <h3 className="get_heading">Email : {Get_Attendece.email} </h3>
              <h3 className="get_heading">Employer Identification Number:{Get_Attendece.employer_identification_number} </h3>
            </div>
            <div className="col-lg-6 col-12">
              <h3 className="get_heading">
                Address : {Get_Attendece.address}{" "}
              </h3>
              <h3 className="get_heading">
                Contact No : {Get_Attendece.contact_number}
              </h3>
              <h3 className="get_heading">Industry :{industry.title}</h3>
              <h3 className="get_heading">
                Description : {Get_Attendece.description}
              </h3>
            </div>
          </div> */}
          {/* <div className="profile_card card_separater d-flex justify-content-end ">
            <div className="d-flex align-items-center justify-content-between w-75">
              <h1 className="text-center Profile_heading extra_margin">
                Level Two Information
              </h1>
              {get_Profile["level_two_profile_status"] ===
              "none" ? null : get_Profile["level_two_profile_status"] ===
                "valid" ? (
                <p className="h4 status_profile">
                  <span
                    class="badge badge-danger bg-success mx-3"
                    style={{
                      opacity: 0.9,
                      boxShadow: "2px 1px 13px -1px green",
                    }}
                  >
                    Valid
                  </span>
                </p>
              ) : (
                <p className="h6 status_profile">
                  <span
                    class="badge badge-danger bg-danger mx-3"
                    style={{ boxShadow: "4px 1px 13px -1px #red" }}
                  >
                    In Valid
                  </span>
                </p>
              )}
            </div>
          </div>
          {get_Profile["level_two_profile_status"] === "none" ? null : (
            <div className="row profile_card">
              <div className="col-lg-6 col-12">
                <h3 className="get_heading">
                  Profile Status :{get_Profile["level_two_profile_status"]}
                </h3>
                <h3 className="get_heading">
                  Passport Number :
                  {user_level_two_profile.passport_number
                    ? user_level_two_profile.passport_number
                    : "----"}
                </h3>
                <h3 className="get_heading">
                  Property Address :
                  {user_level_two_profile["property_address"]
                    ? user_level_two_profile["property_address"]
                    : "----"}
                </h3>
              </div>
              <div className="col-lg-6 col-12"></div>
            </div>
          )} */}
        </div>
      )}
    </>
  );
};

export default CompanyProfile;
